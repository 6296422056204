import React from 'react';
import Helmet from 'react-helmet';

import styled from '@emotion/styled';
import { ThemeWrapper } from 'styles';

const LayoutMain: React.FC = ({ children, ...props }) => {
  return (
    <ThemeWrapper>
      <Helmet
        title="My Broker Tools"
        meta={[
          { name: 'description', content: 'My Broker Tools website' },
          { name: 'keywords', content: 'real estate, mls, idx, broker tool, my broker tools' },
          { name: 'bs:githead', content: process.env.GATSBY_GIT_HEAD },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=0' }
        ]}
      />
      <StyledLayoutMain {...props}>{children}</StyledLayoutMain>
    </ThemeWrapper>
  );
};

export default LayoutMain;

const StyledLayoutMain = styled.main`
  /* height: 100%; */
`;
