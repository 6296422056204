import React from 'react';
import { Global, css } from '@emotion/react';
import theme from './theme.json';

const globalStyles = (
  <Global
    styles={css`
      html,
      body,
      body > #___gatsby,
      body > #___gatsby > #gatsby-focus-wrapper {
        height: 100%;
        > div:first-of-type {
          height: 100%;
        }
      }

      body {
        margin: 0;
        white-space: pre-line;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        color: #1e1e1e;
      }

      hr {
        border-width: 0 0 1px 0;
      }

      #modal-root {
        width: 100%;
      }

      .Toaster {
        height: unset;

        .Toaster__manager-bottom {
          margin-bottom: 10px !important;
        }
      }
      .scrollbar-custom {
        scrollbar-width: thin;
        scrollbar-color: #c7c7ca ${theme.colors.primary};
        &::-webkit-scrollbar {
          position: fixed;
          width: 4px;
          height: 4px;
          background: #c7c7ca;
        }
        &::-webkit-scrollbar-thumb {
          background: ${theme.colors.primary};
        }
        &::-webkit-scrollbar-corner {
          background: #c7c7ca;
        }
      }
    `}
  />
);

export default globalStyles;
