import React from 'react';
import { ThemeProvider } from '@emotion/react';
import globalStyles from './globalStyles';
import theme from './theme.json';

const ThemeWrapper: React.FC = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      {globalStyles}
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
